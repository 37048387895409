@import '../../../../assets/styles/setting';
.cashflow-monthly-total{
  margin-top:10px;

  span{
    display:block;

    &.cashflow-monthly-total-label{
      font-size:11px;
      color:#8c9ba5;
    }
  }
}

.cash-flow-tab{
  // margin:0 15px;
  .nav-tabs{
    margin-bottom:15px;
    .nav-link{
      font-family:$ibmplexRegular;
      font-size:12px;
      color:#626262;
      text-align:center;
      cursor:pointer;
      padding: 0 10px 4px;
      margin-right: 12px;
      &.active{
        background: transparent;
        border-color:transparent;
        font-family:$ibmplexRegular;
        font-size:12px;
        color:#9013fe;
        border-bottom:1px solid #9013fe;
        &:hover{
          border-bottom:1px solid #9013fe;
        }
      }
      &:hover{
        border-color:transparent;
        padding: 0 10px 4px;
      }
    }
  }
  .tab-content{
    margin-bottom:20px;
  }
  .invoice-wrapper{
    .cashflow-table-header{
      border:none;
      border-radius:0;
      border-bottom:1px solid #c6cddd;
    }
    .cashflow-table-body{
      border:none;
      border-bottom:1px solid #c6cddd;
      background: $color_fff;
      &:last-child{
        border-bottom:none;
      }
    }
  }
  .cashflow-invoice-tab{
    .security-heading{
      border-bottom:none;
    }
    .nav-tabs{
      margin-bottom:0;
    }
    .tab-content{
      margin-bottom:0;
    }
  }
  .maintainence-checkbox-block{
    margin: 20px 0 15px;
    >div{
      padding:0;
    }
    label{
      margin-right:15px;
      font-family:$ibmplexRegular;
      font-size:12px;
      color:#8c9ba5;
      input{
        margin-right:8px;
      }
    }
  }
}

.cashflow-table-header{
  margin:0;
  background:#f0f2f3;
  border:1px solid #c6cddd;
  border-radius:3px 3px 0 0;
  h6{
    font-family:$ibmplexRegular;
    font-size:12px;
    color:#8c9ba5;
  }
  >div{
    padding:7px 15px;
  }
}

.cashflow-table-body{
  border-left:1px solid #c6cddd;
  border-right:1px solid #c6cddd;
  padding:8px 0;
  margin:0;
  &.yellow-card{
    background:#fffeec !important;
    border:1px solid #dfe3e6;
  }
  p{
    font-family:$ibmplexRegular;
    font-size:14px;
    color:#3d70b2;
    &.date-block{
      color:#bd10e0;
    }
  }
  span{
    font-family:$ibmplexSemiBold;
    color:$color_fff;
    padding:2px;
    width:90px;
    text-align: center;
    border-radius:3px;
    &.yellow{
      background:#F7CB37;
      border:1px solid #CDA72B;
    }
    &.green{
      background:#7ed321;
      border:1px solid #417505;
    }
    &.orange{
      border:1px solid #417505;
      background:#F3A536;
    }
  }
  >div{
    padding:5px 15px;
  }
  &:nth-child(odd){
    background:#fff;
  }
  &:nth-child(even){
    background:#f5f7fa;
  }
  &:last-child{
    border-bottom:1px solid #c6cddd;
  }
  .pdf-status-block{
    position: absolute;
    right: 20px;
    span{
      font-family:$ibmplexRegular;
      font-size:12px;
      color:$color_000;
      .blue{
        color:#2f8cff;
      }
    }
  }
}
