.switch-container {
	-webkit-transform: translate3d(-50%, -50%, 0);
	transform: translate3d(-50%, -50%, 0);
	cursor: pointer;
}

input[type="checkbox"].switch {
	position: absolute;
	opacity: 0;
}

input[type="checkbox"].switch + div {
	vertical-align: middle;
	width: 30px;
	height: 10px;
	border-radius: 999px;
	background-color: #9b9b9b;
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: background-color, box-shadow;
	transition-property: background-color, box-shadow;
	cursor: pointer;
}

input[type="checkbox"].switch + div span {
	position: absolute;
	font-size: 1.6rem;
	color: white;
	margin-top: 12px;
}

input[type="checkbox"].switch + div span:nth-child(1) {
	margin-left: 15px;
}

input[type="checkbox"].switch + div span:nth-child(2) {
	margin-left: 55px;
}

input[type="checkbox"].switch:checked + div {
	width: 30px;
	background-position: 0 0;
	background-color: #3b89ec;
}

input[type="checkbox"].switch + div {
	width: 30px;
	height: 10px;
}

input[type="checkbox"].switch:checked + div {
	background-color: #7ed321;
}

input[type="checkbox"].switch + div > div {
	width: 10px;
	height: 10px;
	border-radius: inherit;
	background: #ffffff;
	float:right;
	-webkit-transition-timing-function: cubic-bezier(1, 0, 0, 1);
	transition-timing-function: cubic-bezier(1, 0, 0, 1);
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
	-webkit-transition-property: transform, background-color;
	transition-property: transform, background-color;
	pointer-events: none;
	box-shadow:0 2px 4px 0 rgba(0,0,0,0.50);
}

input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(-20px, 0, 0);
	transform: translate3d(-20px, 0, 0);
	background-color: #ffffff;
}

input[type="checkbox"].bigswitch.switch + div > div {
	width: 30px;
	height: 10px;
	margin-top: 1px;
}

input[type="checkbox"].switch:checked + div > div {
	-webkit-transform: translate3d(-20px, 0, 0);
	transform: translate3d(-20px, 0, 0);
}
