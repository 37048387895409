.banner {
    background-image: -webkit-linear-gradient(139deg, rgba(21,196,253,0.79) 0%, #002046 100%);
    height: 200px;
    width: 100%;
    margin-left: 54px;
}

.thumbnail {
    width: 1000px;
    height: 315px;
    object-fit: cover;
    border-radius: 10px;
    position: absolute;
    top: 168px;
    right: 195px;
}

.blog-content {
    margin-bottom: 40px;
    h1 {
        font-size: 32px;
        margin: 15px 0px 15px 0px !important;
    }
    h2 {
        font-size: 28px;
        margin: 15px 0px 15px 0px;
    }

    h3 {
        font-size: 22px;
        margin: 15px 0px 15px 0px;
    }
    p{
        margin: 20px 0px 20px 0px !important;
        line-height: 26px;
        font-size: 14px;
    }
    h4 {
        font-size: 16px;
        font-weight: 600;
        margin: 30px 0px;

    }
    ul{
        margin: 10px 0px 10px 30px;
    }
    li {
        margin: 5px 0px;
        font-size: 14px;
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
    .marker {
        background: yellow;
    }
}

.tab-list {
  border-bottom: 1px solid #DEE2E6;
  color: #838383;
  font-size: 13px;
  .tab {
    margin-right: 30px;
    cursor: pointer;
    &:hover{
      cursor: pointer;
      color:#3d3d3d;
    }
  }
  .tab-active {
    border-bottom: 1px solid #2f8cff;
    color: #2f8cff;
    transition-timing-function: ease-in .2s;
    margin-right: 30px;
    margin-bottom: -1px;
    cursor: pointer;


  }

}

.filter-section {
  .search-input {
    width: 500px;
  }
  .dropdown-filter {
    width: 200px;
    position: relative;
    top: 12px;
    font-size: 12px;

  }
  .filter-button {
    margin-right: 10px;
  }
}
