@import '../../../../assets/styles/setting';
.records-db-container{
  margin-left:215px;
  margin-top: 94px;
  padding: 0 15px;
  h3{
    font-family: $ibmplexSemiBold;
    color: #BFE6FF;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .title{
    font-family: $ibmplexRegular;
    color: #F0A4FF;
    font-size: 12px;
    margin-bottom: 4px;
  }
  .count-stats{
    font-family: $ibmplexRegular;
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 8px;
    span{
      font-size: 10px;
    }
  }
  .dataroom-dashboard{
    margin-left: 60px;
    .no-records-found{
      margin:0px;
    }

  .file-status-card{
    background-image: linear-gradient(0deg, #6542c1 0%, #6189ff 100%);
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.22);
    .progress-card{
      margin-bottom: 20px;
      .progressbar{
        width: 100%;
        background: rgba(255,255,255,0.12);
        height: 6px;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        span{
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          height: 100%;
          width: 30%;
          border-radius: 5px;
          background-image: linear-gradient(0deg, #B4EC51 0%, #DEFD2E 100%);
          display: inline-block;
        }
      }
    }

  }
}

  .gap-summery{
    background-image: linear-gradient(0deg, #6542c1 0%, #6189ff 100%);
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.22);
  }
  .record-activity-table{
    border: 1px solid #C6CDDD;
    margin-bottom: 20px;
    border-radius: 3px;

    table{
      width: 100%;
      thead{
        background: #F7F8FA;
        border-bottom: 1px solid #C6CDDD;
        th{
          font-style: $ibmplexRegular;
          color: #3D70B2;
          font-size: 14px;
          padding: 10px 8px;
        }
      }
      tbody{
        background: #ffffff;
        padding: 0 10px;
        tr{
          border-bottom: 1px solid #F5F7FA;
          cursor: pointer;
          &:hover{
            background: #f7f8fa;
          }
          &:last-child{
            border-bottom: none;
          }
          td{
            font-style: $ibmplexRegular;
            font-size: 12px;
            color: #000;
            padding: 10px 8px;
            &:first-child{
              width: 355px;
            }
            img.profile-pic{
              width: 35px;
              height: 35px;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .record-assign-users{
    border: 1px solid #E0DCDC;
    border-radius: 3px;
    h3{
      font-style: $ibmplexBold;
      color: #000000;
      font-size: 12px;
      background: #F0F0F0;
      border-bottom: 1px solid #E0DCDC;
      padding: 20px;
      margin: 0;
    }
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      background: #ffffff;
      li{
        padding: 15px 20px;
        border-bottom: 1px solid #f1f1f1;
        overflow: hidden;
        &:last-child{
          border-bottom: none;
        }
        img{
          width: 50px;
          height: 50px;
          border-radius: 50%;
          float: left;
          object-fit: contain;
          border: 1px solid #ddd;
        }
        .user-detail{
          margin-left: 60px;
          margin-top:10px;
          h5{
            font-style: $ibmplexRegular;
            color: #000000;
            font-size: 12px;
          }
          a{
            font-style: $ibmplexRegular;
            color: #9B9B9B;
            font-size: 10px;
            display: block;
          }
          span{
            font-style: $ibmplexRegular;
            color: #ffffff;
            font-size: 10px;
            background: #8C9BA5;
            border-radius: 3px;
            padding: 5px 10px;
            display: inline-block;
          }
        }
      }
    }
  }
}

.last-bold{
  font-Family:$ibmplexSemiBold;
}
.records-resource-container{
  margin-left: 260px;
  padding: 0 15px;
  margin-top: 94px;
  .folder-row{
    margin-bottom: 15px;
    .folder-title{
      font-family: $ibmplexLight;
      color: #000000;
      font-size: 20px;
      margin-bottom: 20px;
    }


    .folder-card{
      background: #ffffff;
      padding: 15px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.22);
      margin-bottom: 20px;
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      .downloadapp{
        position: absolute;
        top:0;
        bottom:0;
        right:0;
        left:0;
      }

      &:hover{
        .rec-download-icon{
        opacity:1;
        transition: 1s;
        }
      }

      img{
        width: 30px;
        float: left;
        &.rec-download-icon{
            position:absolute;
            bottom: 10px;
            right: 10px;
            width:15px;
            opacity:0;

        }
      }

      .folder-info{
        margin-left: 40px;
        h4{
          font-family: $ibmplexRegular;
          color: #000000;
          font-size: 16px;
        }
        p{
          font-family: $ibmplexRegular;
          color: #8C9BA5;
          font-size: 12px;
        }
      }
    }
  }


}
.records-report-layout{
  margin-left: 215px;
  margin-top: 90px;
  .tab-left{
    li{
      padding: 5px 15px;
    }
  }

  span{
    cursor: pointer;
    color: #2789ff;
    position: absolute;
    top: 135px;
    right: 20px;
  }
  h1{
    font-size: 24px;
    font-family: $ibmplexRegular;
    margin-bottom: 20px;
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar{
      display: none;
    }
    a{
      font-size: 14px;
      margin-left: 10px;
      .fa-download{
        color: #2789ff;
      }
    }
  }
  #dataReport{
    border: 1px solid #c6cddd;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    border-radius: 3px 3px 0 0;
    margin-bottom: 40px;
    position: relative;
  }
  table{
    background: #ffffff;
    border-collapse:collapse;
    td{
      word-wrap: break-word;
      white-space: normal;
      width: 100%;
      padding: 10px;
      min-width: 100px;
      font-size: 14px;
      font-family: $ibmplexRegular;
      font-size:14px;
      color: #000;
      border: 1px solid #d3d3d3;
    }
    thead{
      tr{
        &:first-child{
          background: rgba(255,255,255,0.07);
          background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
          background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
          td{
            font-family:$ibmplexRegular;
            font-size:12px;
            color: #8c9ba5;
            border-bottom: 1px solid #d3d3d3;
          }
        }
      }
    }
    tbody{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      display: block;
      tr{
        td{
          border-bottom: none!important;
        }
      }
    }
    tbody, thead{
      width: 100%;
      tr{
        table-layout: fixed;
        display:table;
        width:100%;
      }
    }
  }
}

.auto-renamig-block{
  .to-be-reviewed, .reviewed{
    .asset-listing-wrap{
      margin-left: 0;
      &.row{
        margin-left: -15px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  body{
    .records-db-container,.records-resource-container,.action-doc-container,.record-recycle-bin, .record-report-con, .auto-renamig-block{
      margin-top: 155px;
      margin-left: 0;
      .mr-calc-sidebar{
          opacity: 1;
          visibility: visible;
          z-index: 999;
          left: 60px;
          top: 135px !important;
          height: 48px;
          padding-top: 0 !important;
          .mbl-cross{
            display: none;
          }
          ul{
            white-space: nowrap;
            overflow-x: auto;
            margin: 0;
            li{
              display: inline-block;
              a{
                padding: 15px 15px;
                &.active{
                  text-decoration: underline;
                }
              }
              &:last-child{
                padding-right: 60px;
              }
            }
          }
        }
      .dataroom-dashboard{
        margin-left: 45px;
      }
      .record-activity-table{
        overflow-x: auto;
        tr{
          table-layout: auto;
          th,td{
            width: 355px;
          }
        }
      }
    }
    .records-resource-container{
      margin-left: 60px;
      padding: 0;
    }
    .records-db-container{
      .dataroom-dashboard{
        margin: 0 !important;
        padding-left: 60px;
        padding-right: 0; 
      }
    }
    .auto-renamig-block{
      margin: 0 !important;
      margin-left: 60px !important;
      padding: 60px 0 !important;
      margin-top: 100px !important;
      .notice-info{
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 15px;
      }
      .notice-footer{
        left: 60px;
        padding: 10px 5px;
      }
      .to-be-reviewed, .reviewed{
        >div{
          overflow-x: auto;
          a{
            ul{
              display: block;
              padding: 8px 3px 15px !important;
            }
          }
        }
      }
    }
    .action-doc-container{
      .action-dock-block{
        margin-left: 60px !important;
        .action-dock-search{
          width: 100%;
        }
        .download-action-dock{
          top: -30px !important;
        }
        .action-doc-listing-wrp{
          overflow-x: auto;
        }
        table{
          tr{
            table-layout: auto;
          }
        }
        .asset-list-filter-sort{
          >div, .asset-list{
            width: auto;
            display: inline-block !important;
          }
          .filter-block, .asset-list div{
            >div{
              display: inline-block;
              width: 250px;
            }
          }
          .filter-block, .asset-list div{
            display: block;
            white-space: nowrap;
          }
          
        }
        .asset-list{
          width: auto;
          display: inline-block !important;
          >.flex-centered{
            white-space: nowrap;
            display: inline-block;
            >div{
              width: 250px;
              display: inline-block;
            }
            .msn-block{
              >div{
                width: auto;
              }
            }
          }
        }
      }
    }
    .record-recycle-bin{
      margin-left: 60px !important;
      margin-top: 155px !important;
      padding: 0 !important;
      .recycle-listing-wrapper{
        overflow-x: auto;
      }
      .asset-list-filter-sort{
        >div, .asset-list{
          width: auto;
          display: inline-block !important;
        }
        .filter-block, .asset-list div{
          >div{
            display: inline-block;
            width: 150px;
          }
        }
        .filter-block, .asset-list div{
          display: block;
          white-space: nowrap;
        }
        
      }
      .asset-list{
        width: auto;
        display: inline-block !important;
        >.flex-centered{
          white-space: nowrap;
          display: inline-block;
          >div{
            width: 150px;
            display: inline-block;
          }
          .msn-block{
            >div{
              width: auto;
            }
          }
        }
      }
    }
    .record-report-con{
      .records-report-layout{
        margin-left: 60px !important;
        margin-top: 60px !important;
        padding: 0 !important;
        width: auto !important;
        .tab-left li .download-action-dock{
          top: 55px;
        }
        table{
          tr{
            table-layout: auto;
          }
        }
        .asset-list-filter-sort{
          >div, .asset-list{
            width: auto;
            display: inline-block !important;
          }
          .filter-block, .asset-list div{
            >div{
              display: inline-block;
              width: 150px;
            }
          }
          .filter-block, .asset-list div{
            display: block;
            white-space: nowrap;
          }
          
        }
        .asset-list{
          width: auto;
          display: inline-block !important;
          >.flex-centered{
            white-space: nowrap;
            display: inline-block;
            >div{
              width: 150px;
              display: inline-block;
            }
            .msn-block{
              >div{
                width: auto;
              }
            }
          }
        }
        .mr-calc-sidebar{
          opacity: 1;
          visibility: visible;
          z-index: 999;
          left: 60px;
          top: 40px !important;
          height: 48px;
          padding-top: 0 !important;
          .mbl-cross{
            display: none;
          }
          ul{
            white-space: nowrap;
            overflow-x: auto;
            margin: 0;
            li{
              display: inline-block;
              a{
                padding: 15px 15px;
                &.active{
                  text-decoration: underline;
                }
              }
              &:last-child{
                padding-right: 60px;
              }
            }
          }
        }
        #dataReport{
          overflow-x: auto;
        }
        .records-list-wrap{
          overflow-x: auto; 
        }
      }
    }
  }
}