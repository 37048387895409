@import '../../assets/styles/setting';

body{

  .sparta-staticpage-container{

    .flex-centered{
      display: flex;
      display: -webkit-flex;
      flex-flow: wrap;
      -webkit-flex-flow: wrap;
      align-items: center;
      &:before, &:after {
           width: 0;
      }
    }

    .flex-not-centered{
      display: flex;
      display: -webkit-flex;
      flex-flow: wrap;
      -webkit-flex-flow: wrap;
      &:before, &:after {
           width: 0;
      }
    }

    .banner-section{
      position: relative;
      overflow: hidden;
      background-color: #F3FAFE;
      // background-repeat: no-repeat;
      // background-position: center top;
      &::before{
          content: '';
          position: absolute;
          background-image: url(https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/landing/spa.jpg);
          background-size: cover;
          height: 500px;
          left: 0;
          right: 0;
          background-repeat: no-repeat;
      }
      .banner-row{
        position: relative;
         min-height: 550px;
        .left-blk{
          .logo-blk{
            .logo-link{
              img{
                position: absolute;
                top: -40%;
                height: 50px;
              }
            }
          }
          .content-blk{
            h1{
              font-family: $ibmplexMedium;
              font-size: 42px;
              color: $white;
              line-height: 52px;
              margin-bottom: 15px;
            }
            h2{
              font-family: $ibmplexRegular;
              font-size: 32px;
              color: $white;
              line-height: 42px;
              margin-bottom: 30px;
            }
            .open-free-account{
              font-family: $ibmplexMedium;
              font-size: 18px;
              color: #0f2d51;
              background: #FEC836;
              border-radius: 3px;
              display: inline-block;
              line-height: 18px;
              padding: 14px 25px 12px;
              transition: all 0.4s ease-out;
              &:hover{
                transition: all 0.3s ease-in;
                box-shadow: 0 2px 12px 0 #8D8D8D;
              }
            }
          }
        }
        .right-blk{
          .img-blk{
            .banner-image{
              position: relative;
              right: -30px;
              height: 460px;
              top: 50px;
            }
          }
        }
      }
    }

    .description-section{
      background-image: linear-gradient(9deg, #F0F9FF 0%, #F4F9FD 100%);
      border-radius: 6px 6px 100px 100px;
      .description-row{
        padding: 40px 50px;
        p{
          font-family: $ibmplexRegular;
          font-size: 24px;
          color: #0f2d51;
          line-height: 34px;
          span{
            font-family: $ibmplexMedium;
          }
        }
      }
    }

    .investment-section{
      padding: 70px 0;
      background: $white;
      .investment-row{
        text-align: center;
        h3{
          font-family: $ibmplexMedium;
          font-size: 26px;
          color: #0f2d51;
          line-height: 36px;
          margin-bottom: 30px;
        }
        ul{
          li{
            display: inline;
            padding: 0 10px;
            img{
              // height: 25px;
            }
          }
        }
      }
    }

    .footer{
        background-color:#191c26;
        padding: 30px 15px;
        img{
            width: 100px;
            margin-bottom: 10px;
        }
        p{
            font-family: $ibmplexLight;
            font-size: 13px;
            color: #bec0c7;
            &.copyrights{
              a{
                color: #bec0c7;
                &:hover{
                  text-decoration: underline;
                }
              }
            }
        }
    }

    .plans-wrapper{
        padding: 60px 0;
        background-image: linear-gradient(25deg, #1A5374 0%, #25759A 100%);
        >.container{
            >.row{
                >div{
                    display: flex;
                    -webkit-display: flex;
                    flex-flow: wrap;
                    -webkit-flex-flow: wrap;
                    &:before,&:after{
                        width: 0;
                    }
                }
            }
        }
        .card-wrapper{
            border: 1px solid #04bbff;
            border-radius: 6px;
            width: 100%;
            padding-bottom: 90px;
            transition: all 0.4s ease-out;
            .card-title{
                padding: 15px 40px;
                border-bottom: 1px solid #04bbff;
                h4{
                    font-family: $ibmplexBold;
                    font-size: 26px;
                    color: #ffffff;
                    margin-bottom: 10px;
                }
                p{
                    font-family: $ibmplexMedium;
                    font-size: 16px;
                    color: #ffffff;
                }
            }
            .card-features{
                padding: 15px 40px;
                ul{
                    list-style-type: none;
                    li{
                        font-family: $ibmplexRegular;
                        font-size: 14px;
                        color: #ffffff;
                        margin-bottom: 10px;
                        position: relative;
                        padding-left: 15px;
                        &:before{
                            content: '';
                            position: absolute;
                            height: 6px;
                            width: 6px;
                            border-radius: 50%;
                            background-color: #ffffff;
                            left: 0;
                            top: 7px;
                        }

                    }
                }
                .sec-btn, .prim-btn{
                    position: absolute;
                    bottom: 30px;
                    text-align: center;
                    left: 55px;
                    right: 55px;
                }
                .sec-btn{
                    border: 1px solid #FEC836;
                    padding: 10px;
                    font-family: $ibmplexRegular;
                    font-size: 18px;
                    color: #FEC836;
                    border-radius: 4px;
                    display: inline-block;
                    transition: all 0.4s ease-out;
                    &:hover{
                        transition: all 0.3s ease-in;
                        background-color: #FEC836;
                        color: #0f2d51;
                    }
                }
                .prim-btn{
                    border: 1px solid #FEC836;
                    padding: 10px;
                    font-family: $ibmplexRegular;
                    font-size: 18px;
                    color: #0f2d51;
                    background-color: #FEC836;
                    border-radius: 4px;
                    display: inline-block;
                    transition: all 0.4s ease-out;
                    &:hover{
                      transition: all 0.3s ease-in;
                      box-shadow: 0 2px 12px 0 #8D8D8D;
                    }
                }
            }
            &:nth-child(2){
                background-color: #124465;
            }
            &:hover{
              transition: all 0.3s ease-in;
              background: #124465;
            }
        }
    }

    .manage-features-wrapper{
        padding: 20px 0 60px;
        background-image: linear-gradient(0deg, #D0EDFF 0%, #F0F9FF 100%);
        border-radius: 6px 6px 6px 0;
        h4{
            font-size: 24px;
            color: #002046;
            font-family: $ibmplexBold;
            margin-bottom: 20px;
        }
        img{
            max-width: 100%;
            margin-bottom: 50px;
        }
        ul{
            list-style-type: none;
            li{
                font-family: $ibmplexRegular;
                font-size: 14px;
                color: #002046;
                margin-bottom: 10px;
                position: relative;
                padding-left: 15px;
                &:before{
                    content: '';
                    position: absolute;
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    background-color: #7232d4;
                    left: 0;
                    top: 7px;
                }

            }
        }
        .prim-btn{
            border: 1px solid #fcd876;
            padding: 15px 30px;
            font-family: $ibmplexMedium;
            font-size: 18px;
            color: #0f2d51;
            background-color: #FEC836;
            border-radius: 4px;
            display: block;
            max-width: 490px;
            margin: 40px auto 20px;
            transition: all 0.4s ease-out;
            &:hover{
              transition: all 0.3s ease-in;
              box-shadow: 0 2px 12px 0 #8D8D8D;
            }
        }
    }

    .manage-investment-block{
        padding: 0 0 60px;
        background-color: #ffffff;
        img{
            height: 500px;
            position: relative;
            right: 115px;
        }
        .manage-card{
            padding: 50px 70px 70px;
            box-shadow: 0 13px 33px 0 rgba(0, 0, 0, 0.08);
            padding-bottom: 2px;
        }
        h2{
            font-family: $ibmplexBold;
            font-size: 26px;
            color: #002046;
            line-height: 36px;
            margin-bottom: 30px;
            margin-top: 5px;
        }
        ul{
            list-style-type: none;
            li{
                font-family: $ibmplexRegular;
                font-size: 14px;
                color: #002046;
                margin-bottom: 15px;
                position: relative;
                padding-left: 15px;
                &:before{
                    content: '';
                    position: absolute;
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    background-color: #7232d4;
                    left: 0;
                    top: 7px;
                }

            }
        }
    }

  }

}


//mobile portrait view //
@media (min-width: 320px) and ( max-width: 767px){
body {

      .sparta-staticpage-container{
        .banner-section{
          &::before{
            min-height: 95vh;
          }
          .banner-row{
            padding: 50px 0 0;
            .left-blk{
              padding-bottom: 50px;
              .logo-blk{
                margin-bottom: 30px;
                .logo-link{
                  img{
                    position: relative;
                    top: 0;
                  }
                }
              }
              .content-blk{
                h1{
                  font-size: 38px;
                  line-height: 48px;
                }
                h2{
                  font-size: 28px;
                  line-height: 38px;
                }
              }
            }
            .right-blk{
              .img-blk{
                .banner-image{
                  height: 250px;
                  top: 0;
                  right: 0;
                }
              }
            }
          }
        }

        .description-section{
          border-radius: 6px 6px 30px 30px;
          .description-row{
            padding: 40px 10px;
            p{
              font-size: 20px;
              line-height: 30px;
            }
          }
        }

        .investment-section{
          padding: 50px 0;
          .investment-row{
            ul{
              li{
                padding: 0 5px;
                img{
                  width: 85px;
                }
              }
            }
          }
        }

        .manage-investment-block {
          .manage-card{
            padding: 50px 15px 50px;
            img{
              height: 250px;
              display: block;
              margin: 0 auto 30px;
              text-align: center;
              right: 0;
              max-width: 100%;
            }
          }
        }

        .manage-features-wrapper{
          padding: 20px 0 45px;
          img{
            // width: 90%;
            // height: auto;
          }
          .prim-btn{
            font-size: 12px;
            padding: 15px 10px;
          }
        }

        .plans-wrapper {
          padding: 50px 0 30px;
          .outer-wrapper{
            margin-bottom: 30px;
          }
        }
      }

  }
}

//mobile landscape view //
@media (min-width: 576px) and (max-width: 767px) {
  body {

  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body{

    .sparta-staticpage-container{
      .banner-section{
        .banner-row{
          .left-blk{
            .logo-blk {
              .logo-link {
                img{
                  top:-30%;
                }
              }
            }
          }
           .right-blk{
             .img-blk {
               .banner-image{
                 right: 25px;
               }
             }
           }
        }
      }

      .manage-investment-block{
         img{
           right: auto;
           display: block;
           margin: 0 auto 30px;
           text-align: center;
         }
      }
    }

  }
}

@media (min-width: 992px) and (max-width: 1199px) {
body{

    .sparta-staticpage-container{
      .banner-section{
        .banner-row{
          .left-blk{
            .logo-blk {
              .logo-link {
                img{
                  top:-40%;
                }
              }
            }
          }
        }
      }

      .manage-investment-block{
        img{
          height: 400px;
        }
      }

    }

  }
}

@media (min-width: 1200px) {
  body{

  }
}
