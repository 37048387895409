@import '../../../../assets/styles/setting';

body{
  .technical-inspection-form-v1{
    margin-left: 70px;
    padding: 100px 0 30px;
    .t011-header-count{
      margin-top: 20px;
      .folder-card{
        background: #ffffff;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        // cursor: pointer;
        margin: 0 0 5px;
        .folder-info{
          font-family: $ibmplexRegular;
          font-weight: 600;
          padding: 5px;
          &.vl{
            padding-left: 10px;
            &.color-blue{
              border-left: 10px solid #043BFC;
              color: #043BFC;
            }
            &.color-orange{
              border-left: 10px solid #7bb4ec;
              color: #7bb4ec;
            }
            &.color-green{
              border-left: 10px solid #90ed7d;
              color: #90ed7d;
            }
            &.color-brown{
              border-left: 10px solid #f25d80;
              color: #f25d80;
            }
            h4{
              font-size: 30px;
              font-weight: 500;
            }
            p{
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
    .table-ui-component{
      position: relative;
      margin-top: auto;
      overflow: auto;
      .table-height{
        background: none;
        // height: auto!important;
        overflow: unset;
        table{
          &.table-sticky{
          // display: block;
          overflow-x: auto;
        }
        &.table-sticky-list{
          height: 100%;
        }
          thead{
            background: #DFE8ED;
            tr{
              z-index: 1;
              position: -webkit-sticky;
              position: sticky;
              th{
                color: #6D9BB9;
                padding: 6px;
                border-right: 1px solid #D1CFCF;
                background: #DFE8ED;
                z-index: 99;
                &.table-head,&.sub-head{
                  border-right: none;
                  &:last-child{
                    border-right: none;
                  }
                }
                span{
                  cursor: default;
                }
              }
            }
          }
          tbody{
            background: none;
            tr{
              &:nth-child(odd){
                background-color: #EBEFF6;
                color: #000000;
              }
              &:nth-child(even){
                background-color: #F5F7FA;
                color: #000000;
              }
              td{
                border-right: 1px solid #D1CFCF;
                padding: 5px 8px;
              }
            }
            .no-rcrd-found-tbody{
              .no-records-found{
                &.no-records-found-t011-v1{
                  width: 100%!important;
                }
              }
            }
          }

          .form-group-edit{
            textarea{
              background: transparent;
            }
          }
          .react-datepicker__input-container{
              input[type="text"]{
                background: transparent;
              }
            }
        }
      }
      .outside-table{
        border-bottom: 1px solid #717171;
        width: 100px;
        &.started-date{
          left: 41.5%;
        }
        &.completed-date{
          left: 49%;
        }
        &.disposition{
          left: 34%;
          border-bottom: none;
          padding-top: 8px;
        }
        &.priority{
          left: 26%;
          border-bottom: none;
          padding-top: 8px;
        }
        .Select-control{
          background: transparent;
          border: none;
          .Select-clear{
            display: none;
          }
        }
        .react-datepicker-wrapper{
            .react-datepicker__input-container{
              input[type="text"]{
                background: transparent;
              }
            }
          }
          &.enable-select-clear{
            .Select-control{
              .Select-clear{
                display: block;
              }
            }
          }
      }
      textarea{
        background: transparent;
        word-break: break-word;
        width: 100%;
      }
    }
    .table-header{
      margin-top : 5px;
      h4{
        font-family: $ibmplexRegular;
        font-size: 18px;
        color: #BD10E0;
      }
      .form-group{
        width: 275px;
        margin-bottom: 6px;
        .Select-value-label{
          color: #c4c4c4!important;
          font-size: 14px;
        }
        &.enable-select-clear{
          .Select-control{
            .Select-clear{
              display: block;
            }
          }
        }
      }
      .Select-control{
        .Select-value-label{
          color: #BD10E0 !important;
        }
        .Select-clear{
            display: none;
          }
      }
      .delete-prs{
        display: inline-block;
        position: relative;
        top: -18px;
        margin-left: 15px;
        border-radius: 3px;
        li{
          &:last-child{
            border-right: none;

          }
          padding: 5px 5px;
          margin-right: auto;
          border-left: 1px solid rgb(47, 140, 255);
          span{
            color: rgb(47, 140, 255);
            font-size: 14px;
            img{
              width: 15px;
              cursor: pointer;
              margin-right: 5px;
              vertical-align: sub;
            }
          }
        }
      }
      .full-screen-block{
        float: right;
        ul{
          li{
            &:first-child{
              width: auto;
              font-size: 14px;
              font-weight: 700;
              color: rgb(47, 140, 255);
            }
            &.disposition{
              width: 130px;
              margin-right: auto;
            }
            width: 130px;
            margin-bottom: 10px;
            .Select-control{
              .Select-value-label{
                font-size: 14px;
              }
              .Select-clear{
                display: block;
              }
            }
          }
        }
      }
    }
    .custom-select-block{
      .form-group{
        margin-bottom: auto;
      }
      .Select-control{
        // border: 1px solid #C4C4C4;
        // background: #ffffff;
        // border-radius: 4px;
        .Select-multi-value-wrapper{
          .Select-placeholder{
            padding-left: 10px;
          }
        }
        .Select-arrow-zone{
          top: 3px;
        }
        .Select-clear{
          display: none;
        }
      }
    }
    .dropable-zone{
      border: 2px dashed #717171;
      margin-bottom: 30px;
    }
    .drop-excel{
      padding: 50px 80px;
      .sample{
        font-size: 16px;
        text-align: right;
        margin-bottom: 5px;
        a{
          color: #368FFB;
          font-weight: 600;
        }
      }
      .notice-info{
        margin-left: auto;
      }
    }
    .display-tabs{
      position: absolute;
      right: 52px;
      // margin-top: 20px;
      ul{
        border: 1px solid #043BFC;
        border-radius: 3px;
        li{
          padding: 2px 15px 5px;
          border-right: 1px solid #043BFC;
          cursor: pointer;
          &:last-child{
            border-right: none;
          }
          &.list-inline-item:not(:last-child){
            margin-right: auto;
          }
          &.active{
            background: #DBEAFB;
            color: #043BFC;
          }
          &.inactive-bg{
            background: #DAD9D9;
          }
          img{
            width: 18px;
          }
        }
      }
    }
    .application-edit-side-bar{
      .edit-bar-content-blk{
        .body-content{
          position: unset;
          .sidebar-form{
            form{
              .form-group{
                .tech-sum-item{
                  .custom-select-block{
                    .Select-control{
                      border: none;
                      border-radius: 0;
                      border-bottom: 1px solid #717171;
                      .Select-clear{
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .top-content{
          .Select-control{
            .Select-clear{
              display: none;
            }
          }
        }
      }
    }
    .technical-edit-sidebar{
      .body-content{
        .submit-block{
          left: 0;
        }
        .form-group{
          &.enable-select-clear{
            .Select-control{
              .Select-clear{
                display: block;
              }
            }
          }
        }
      }
    }
  }
  .techMsn-switcher{
    top: 42px;
  }
  .form-header{
    .select-form-block{
      .dropdown-menu{

      }
    }
  }
}
