@import '../../../../assets/styles/setting';
.security-deposit-wrapper{
  margin-bottom:15px;
  p{
    line-height: 16px;
    &.security-type{
      font-family:$ibmplexRegular;
      font-size:16px;
      color:#194886;
    }
  }
  span{
    font-family:$ibmplexRegular;
    font-size:14px;
    color:#000;
    .attachment-block{
      margin-right:4px;
    }
  }
  h6{
    font-family:$ibmplexRegular;
    font-size:12px;
    color:#8b8d91;
    &.transaction-heading{
      font-family: $ibmplexSemiBold;
      margin-bottom:20px;
      color:#bd10e0;
    }
    .add-trasaction{
      margin-left: 4px;
    }
  }
  .security-heading{
    border-bottom:1px solid #e4e4e4;
    margin:0;
    padding: 12px 15px;
    cursor: pointer;
 
    h6{
      margin-bottom:6px;
    }
  }

  .cash-non-cash-trxn-row{    
    marign-bottom:10px;
    cursor: pointer;
    padding-top:10px;    
    padding-bottom:10px;
  }

  .cash-non-cash-trxn-row:hover{
    background:#e4e4e4;
  }

  .security-element{
    background: rgba(255,255,255,0.07);
    background: -moz-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(237,237,237,0.07)), color-stop(0%, rgba(255,255,255,0.07)), color-stop(0%, rgba(0,0,0,0.07)), color-stop(100%, rgba(0,0,0,0)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(237,237,237,0.07) 0%, rgba(255,255,255,0.07) 0%, rgba(0,0,0,0.07) 0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
    background:#f5f7fa;
    border-radius:0 0 3px 3px;
    padding: 15px 12px;
    .transaction-wrapper{
      padding-bottom: 15px;
      border-bottom: 1px solid #e4e4e4;
      margin-bottom: 15px;
      &:last-child{
        margin-bottom:0;
        padding-bottom:15px;
        border-bottom: none;        
      }
    }
  }
}



// xsmall devices
@media (max-width: 575.98px) {
  .security-heading{
    .security-type{
      margin-bottom: 4px;
    }
    >div{
      padding: 15px 0;
    }
    .transaction-inner-wrap{
      >div{
        padding:15px;
      }
    }
  }


}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .security-heading{
    >div{
      padding: 15px 0;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contract-container{
    .security-heading{
      >div{
        padding:0;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .contract-container{
    .highter {
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #FB5353;
      cursor: pointer;
      box-shadow: 0 0 0 0 rgba(251,83,83,0.5);
      animation: pulse 2s infinite;
     }
    .security-heading{
      >div{
        padding:0;
      }
    }
  }
}
