
@import '../../../../assets/styles/setting';


body{
  .other-links{
    li{
      a{
        font-size: 12px!important;
      }
    }
  }
  .asset-list-width{
    span{
      width: auto !important;
    }
  }
  .fleet-cashflow-data{
    margin-bottom: 10px;
    overflow: hidden;
    .list-inline{
      float: right;
      .list-inline-item{
        text-align: right;
        margin-left: 15px;
        padding-left: 15px;
        border-left: 1px solid #a8a8a8;
        &:first-child{
          border-left: none;
        }
        span{
          color:$color_000;
          font-size: 13px;
        }
        h6{
          color: #a8a8a8;
        }
      }
    }
  }
  .generate-select-boxes{
    span{
      text-align: right;
      float:right;
    }
    .caption-ui{
      color: #000;
    }
    .list-inline-item{
      display: inline-grid;
      span{
        color:#8b8d91;
      }
    }
  }
  .generate-finance-heading{
    .beta{
      font-size: 20px;
      text-align: left;
      font-weight: 300;
      font-family: $ibmplexRegular;
      span{
        color: #bd10e0;
      }
      @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
    }
    .block-subs{
      @include elementStyles(13px, #838383, normal, normal, $ibmplexRegular, null, null);
      margin-bottom: 15px;
    }
    .portfolio-description{
      margin-left: 23px;
      position: absolute;
      left: 15px;
      padding-top: 5px;
      font-size: 12px;
      color:#a8a8a8;
    }
  }
  .portfolio-fixed-header{
    // position: fixed;
    // top: 65px;
    right: 0;
    padding: 20px 20px 15px 0;
    background-color: #ffffff;
    z-index: 990;
    padding-bottom: 0px!important;
    h2{
      position: relative;
      top: 8px;
    }
  }
  .cashflow{
    h6{
      padding-top: 5px;
      padding-bottom: 5px;
      .pretty-number-override-css{
          font-size: 14px!important;
      }
      span{
        color: #000000;
        display: block;
        text-align: left;
        font-size:12px;
        font-family: $ibmplexRegular;
        text-transform: none;
      }
    }
  }
  .asset-listing-wrap{
    margin-left: 60px;
    &.fleet-list{
      font-size: 12px;
      padding: 0 15px 15px;
      h1{
        @include elementStyles(16px, #bd10e0, normal, normal, $ibmplexRegular, null, null);
        margin-bottom: 3px;
      }
      .block-subs{
        @include elementStyles(13px, #838383, normal, normal, $ibmplexRegular, null, null);
        margin-bottom: 15px;
      }
      h6{
        .pretty-number-override-css{
            font-size: 12px;
        }
        img:nth-of-type(1){
          padding-top: 5px;
          padding-bottom: 5px;
          border-radius: 50%;
          width: 20px;
          margin-right: 5px;
        }
      }
      .asset-model{
        color:#a8a8a8!important;
      }
      .portfolio-type{
        background-color: #56b4da;
        color: #FFFFFF;
        padding: 2px 10px 2px 10px;
        font-size: 11px;
        font-weight: bold;
      }
      .caption-ui{
        color:#000000;
      }
      .theta{
        color: #a8a8a8;
      }
      .asset-list{
        .portfolio-description{
          font-size: 12px;
          color: #a8a8a8;
        }
      }
      .asset-listing-body{
        margin-top: 75px;
        .no-records-found{
          a{
            position:relative;
            top:10px;
            .add-new{
              width: 150px;
              height: 30px;
            }
          }
        }
        .list-loader{
          left:53%!important;
        }
      }
      .action-icon{
        margin-left:5px;
        float:right;
      }
    }

  }
  .form-check-input{
    margin-left:-1.25em;
    position: relative;
    margin-right: 15px;
    margin-top: .3rem;
  }
  .add-asset-button{
    border:1px solid #007bff;
    border-radius:3px;
    background-color: #007bff;
    width:170px;
    cursor: pointer;
    font-size:12px;
    color:#fff!important;
    text-align:center;
    position: relative;
    top:-5px;
    -webkit-transition-timing-function: linear;
    -webkit-transition: background-color 2s ease-in; /* For Safari 3.1 to 6.0 */
    transition-timing-function: linear;
    transition: background-color 2s ease-in;
  }
  .add-asset-button:disabled{
    cursor: no-drop;
    color:#007bff!important;
  }
  .remove-portfolio-assets{
    border:1px solid #ff5e5e;
    border-radius:3px;
    background-color: #fff;
    width:190px;
    cursor: pointer;
    height:28px;
    font-size:12px;
    color:#ff5e5e!important;
    text-align:center;
    span{
      float: none !important;
    }
  }
  .portfolio-footer{
    padding: 17px;
    background:#ffffff;
    height:64px;
    position:fixed;
    bottom:0px;
    left: 40px;
    right: 0px;
    box-shadow: 0px 2px 4px 4px rgba(0,0,0,0.3);
    .btn{
      margin-top: 15px;
      margin-right: 15px;
    }
  }





// simulation block css

  .simulation-header{
    margin-right:15px;
    margin-top: 54px;
    .Select{
      cursor: pointer;
      min-width: 200px;
      max-width: 370px;
      margin-left: 13px;
      margin-bottom: 0;
      .Select-control{
        border-bottom:0;
        &:hover{
          border-bottom:none !important;
          box-shadow: 0 0 0 !important;
        }
        .Select-clear{
          display:none;
        }
         .Select-value-label{
          color:#bd10e0 !important;
          font-size: 16px;
        }
        .Select-arrow-zone{
          right: 22px !important;
          top: 5px !important;
        }
      }
      .Select-menu-outer{
        z-index: 99;
      }
    }
    h3{
      position:relative;
      .view-exprt-btn{
        padding-left: 16px;
      cursor: pointer;
      font-size: 14px;
      float: right;
      padding: 5px 24px;
      color: #fff;
      position: absolute;
      right: 0;
      top: 6px;
      &:hover{
        color:#fff;
      }
      }
    }

    .dropdown{
      button{
        background: transparent;
        border: 0;
        color: #bd10e0;
        &:active, &:hover, &:focus{
          background: transparent;
          box-shadow: 0 0 0 !important;
          color: #bd10e0;

        }
      }
    }
    label{
      margin-bottom:0 ;
    }
    .simulation-date-block{
      margin:0 15px;
      .date-block{
        margin-right:15px;
        input{
          background: transparent;
          border-bottom:1px solid #ccc;
        }
      }
      .total-value{
        h6{
          color:#a8a8a8;
        }
        span{
          font-size: 14px;
        }
        position:absolute;
        right: 35px;
      }
      .update-list{
        border:1px solid #2f8cff;
        font-family:$ibmplexRegular;
        font-size:12px;
        color:#2f8cff;
        border-radius:3px;
        background: $color_fff;
        padding: 6px 26px;
        margin-right: 15px;
        display:inline-block;
      }
      .primary-btn{
        padding: 6px 26px;
        display:inline-block;
        color:$color_fff;
      }
    }
  }


  .react-datepicker-popper{
    z-index: 2;
  }
  .simulation-block{
    flex-flow:nowrap !important;
    margin: 0 15px;
    &.header-sep-block{
      .spacing-cls{
        padding:10px 10px !important;
        border:1px solid #ccc;
        border-left:0;

        &.month-blk{
          width: 180px;
          flex-basis: 180;
          background: #fff;
          position:relative;
          z-index:1;
          box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
          -webkit-box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
          -moz-box-shadow:5px 0 10px -5px rgba(0,0,0,0.4);
          border-left:1px solid #ccc;
        }
        &.total-block{
          width: 200px;
          flex-basis: 300;
           background: #fff;
           position:relative;
           z-index:1;
           -webkit-box-shadow: -5px 0 10px -5px rgba(0,0,0,0.4);
           -moz-box-shadow:-5px 0 10px -5px rgba(0,0,0,0.4);
           box-shadow: -5px 0 10px -5px rgba(0,0,0,0.4);
        }
      }
      .flex-shrink-flow{
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        .spacing-cls{
          flex-basis: 220px;
          flex-grow: 0;
          flex-shrink: 0;
          &.border-right{
            border-right: 1px solid #ccc;
            &:last-child{
              border-right: none !important;
            }
          }
        }
      }
    }

    .inner-width-block{
      overflow: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      // table  scss
    .row-header{
      background: #fff;
      // border-bottom: 1px solid #ccc;
      margin:0;
      flex-shrink: 0;
      flex-grow: 0;
      flex-wrap: nowrap;
      span{
        font-family:$ibmplexRegular;
        font-size:14px;
        color:#8c9ba5;
        &.caption-ui{
          font-size: 12px;
        }
      }
      p{
        font-family:$ibmplexRegular;
        font-size:14px;
        color:#bd10e0;

      }
      a{
        font-family:$ibmplexRegular;
        font-size:12px;
        color:#2f8cff;
        &:hover{
          color:#2f8cff;
          &:hover{
            color:#2f8cff;

          }
        }
      }
      .item-block{
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        width: 220px;
        &:last-child{
          border-right:0;
        }

        >.flex-centered{
          padding:10px 15px;
          border-bottom:1px solid #ccc;
          .first-block{
            width:60%;

          }
          .second-block{
            width:40%;
            text-align: right;
            img{
              height:40px;
              width:40px;
              object-fit: contain;
            }
          }
        }
        .item-detail-block{
          height: 59px;
          padding: 15px 0;
          border-bottom: 1px solid #ccc;
          margin: 0 15px;
          &.neg-value{
            .pretty-number-override-css {
              color:#ff5e5e !important;
            }
          }
          .month-detail{
            padding: 4px 5px ;

            font-size: 14px;
              .date-block{
                font-family:$ibmplexRegular;
                color:#8b8d91;
                font-size: 14px;
              }
              .price-block{
                display: block;
                font-size: 14px;
                &.red{
                  color:#ff5e5e;
                }

              }

          }
          &:last-child{
            border-bottom:0;
          }
        }


      }
    }
  }

  .simulation-month-sidebar{
    background:#fff;
    border: 1px solid #ccc;
    border-right: 0;
    box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
    -webkit-box-shadow: 5px 0 10px -5px rgba(0,0,0,0.4);
    -moz-box-shadow:5px 0 10px -5px rgba(0,0,0,0.4);
    position: relative;
    width: 180px;
    z-index: 0;

  }

  .simulation-total-block{
    -webkit-box-shadow: -5px 0 10px -5px rgba(0,0,0,0.4);
    -moz-box-shadow:-5px 0 10px -5px rgba(0,0,0,0.4);
    box-shadow: -5px 0 10px -5px rgba(0,0,0,0.4);
    width: 200px;
    z-index: 1;

  }

  .simulation-total-block, .simulation-month-sidebar{
    border: 1px solid #ccc;
    border-top: 0;

    position: relative;
    background: #fff;

    .row-header{
      border-left:none;
      .total-block{
        background:#f5f7fa;
        border-bottom:1px solid #ccc;
        &.view{
          padding:10px 3px;
        }
        &.list{
          height: 90px;
          padding-top: 40px;
        }
        p{
          font-family:$ibmplexRegular;
          font-size:14px;
          color:#bd10e0;
          padding:0 15px;
          width:100%;

        }
      }
      .item-detail-block{
        padding:15px 0;
        border-bottom: 1px solid #ccc;
        margin: 0 15px;
        height: 59px;
        &.neg-value{
          .pretty-number-override-css {
            color:#ff5e5e !important;
          }
        }
        p{
          padding:4px 5px;
          font-size: 14px;
          color: #8b8d91;
        }
        &:last-child{
          border-bottom:none;
        }
      }
    }
   }
  }
}
