// Variables Declaration
$backgroundGrey: #F2F2F2;
$borderGrey: #DDDDDD;
$labelGrey: #717171;
$placeholderGrey: #A9A9A9;
$supportTextGrey: #A0A0A0;
$labelOnGradient: #FF75F7;
$supportTextOnGradient: #BE9EFF;
$pageHeader: #22345D;
$moduleHeader: #B225FF;
$sectionL1Header: #691AD3;
$sectionL2Header: #48128F;
$sectionL2HeaderGradient: #FCB2FF;
$widgetHeaderBackground: #EEE9F6;
$linkBlue: #2F8CFF;
$linkBlueDark: #2670CA;
$highlight: #F3F7FF;
$green: #2DBD17;
$red: #EA2222;
$white: #FFFFFF;
$black: #000000;
$infoBackground: #F9F5C6;
$infoTextAndBorder: #98944D;

$ibmplexLight : 'Conv_IBMPlexSans-Light';
$ibmplexRegular : 'Conv_IBMPlexSans-Regular';
$ibmplexMedium : 'Conv_IBMPlexSans-Medium';
$ibmplexSemiBold : 'Conv_IBMPlexSans-SemiBold';
$ibmplexBold : 'Conv_IBMPlexSans-Bold';

$color_1b2 : #1d51b2;
$color_fff : #ffffff;
$color_3ff : #cd93ff;
$color_b9b :#9b9b9b;
$color_000 : #000;
$color_6f0 : #9996f0;
$color_cff : #2f8cff;
$color_238 : #84D238;
$color_083: #FC8083;
$color_9e4:#9439e4;

$font20: 20px;
$font16: 16px;
$font14: 14px;
$font12: 12px;
// extends Mixins
@mixin elementStyles($size: null, $color: null,  $lineHeight: null, $weight: null, $fontFamily: null, $margin: null, $padding: null){
  @if $size != null {
    font-size: $size;
  }
  @if $lineHeight != null {
    line-height: $lineHeight;
  }
  @if $weight != null {
    font-weight: $weight;
  }
  @if $color != null {
    color: $color;
  }
  @if $fontFamily != null {
    font-family: $fontFamily;
  }
  @if $margin != null {
    margin: $margin;
  }
  @if $padding != null {
    padding: $padding;
  }
}
%primary-botton{
  background: #2F8CFF;
  border: 1px solid #2670CA;
  border-radius: 3px;
  color: #ffffff;
  font-family: $ibmplexMedium;
  font-size: 14px;
  cursor: pointer;
  &:hover{
    color: #ffffff;
  }
  &:focus, &:active{
    box-shadow: 0 0 0;
  }
}
%secondary-button{
  background: #FFFFFF;
  border: 1px solid #2670CA;
  border-radius: 3px;
  color: #ffffff;
  font-family: $ibmplexMedium;
  font-size: 14px;
  cursor: pointer;
}

.ternary-button{
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 3px;
  color: #ffffff;
  font-family: $ibmplexMedium;
  font-size: 14px;
  cursor: pointer;
  padding: 6px 18px;
}

.sign-button{
  background: transparent;
  border: 1px solid #2670CA;
  border-radius: 3px;
  color: #2670CA;
  font-family: $ibmplexMedium;
  font-size: 14px;
  cursor: pointer;
  padding: 6px 18px;
  &:hover{
    background: #2670CA;
    color: #ffffff;
  }
}



.pricing-button{
  background: transparent;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
  margin-right: 20px;
  &.selected{
    border-bottom: 1px solid #ffffff;
  }
}

%danger-button{
  background: #FF0000;
  border: 1px solid #A60E0E;
  border-radius: 3px;
  color: #ffffff;
  font-family: $ibmplexMedium;
  font-size: 14px;
  cursor: pointer;
}


input[type="text"]{
  &:disabled{
    background: #f9f9f9;
  }
}
input{
  &:disabled{
    background: #f9f9f9;
  }
}
button{
  cursor: pointer;
}


.form-status-initiated, .status1 {
  background-color: #9ED5FF;
}
.status-details-popover{
  .popover-body{
    margin-left: 10px;
    ul{
      li{
        &:first-child{
          padding: 6px 12px;
        }
      }
    }
    .status-info{
      width: 10px;
      display: inline-block;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }
    .initiated{
      background-color: #9ED5FF;
    }
    .genral-info{
      background-color: #2993FF;
    }
    .launched,
    .inprogress{
      background-color: #9b9a9b;
    }
    .overdue{
      background-color: #FF0100;
    }

    .draft{
      background-color: #2c6fb2;
    }

    .review{
      background-color: #ecd910;
    }

    .approved{
      background-color: #89d153;
    }

    .published{
      background-color: #396a07;
    }
  }
}
.status-details{
  img{
    position: absolute;
    right: 0px;
    cursor: pointer;
  }
}

.form-status-launched,
.form-status-inprogress, .status2 {
  background-color: #9b9a9b;
}

.form-status-draft, .status3 {
  background-color: #2c6fb2;
}

.form-status-in-review, .status5 {
  background-color: #ecd910;
}

.form-status-approved, .status6 {
  background-color: #89d153;
}

.form-status-published, .status7 {
  background-color: #396a07;
}

.initiated {
  //background-image: url('/img/status_images/initiated_badge.png');
  background-color: #9ED5FF;
}

.projectform-status{
  text-align: left;
  border-radius: 4px;
  padding: 2px 9px;
  margin-top: 2px;
  display: inline-block;
  font-size: 13px;
  color: #fff;
}

.launched {
  //background-image: url('/img/status_images/launched_badge.png');
  //color: #ABB3FC;
  //background-color: #ABB3FC;
  background-color: #A5A4A5;
}

// .highter{
//     display: inline-block;
//     background: #FB5353;
//     position: relative;
//     display: block;
//     font-weight: 100;
//     text-align: center;
//     letter-spacing: -1px;
//     border: none;
//     border-radius: 50%;
//     cursor: pointer;
//     top: -5px;
//     width: 8px;
//     height: 8px;
//     box-shadow: 0 0 0 0 rgba(251,83,83,0.5);
//     -webkit-animation: pulseRoberyList 1.5s infinite;
// }



@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.8);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.8);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.8);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }

}
