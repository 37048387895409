@import '../../../assets/styles/setting';
.modal-dialog{
    .modal-content{
        max-height: 100vh;
    }
    &.modal-tform-delete-pictures{
        max-width: 100%!important;
        max-height: 100%!important;
        margin-top: auto !important;
        .modal-body{
            height: auto !important;
        }
    }
    &.app-switcher-modal{
        max-width: 100%!important;
        max-height: 100%!important;
        margin-top: auto !important;
        .modal-body{
            height: 100vh;
            max-height: none;
        }
    }
}
.application-switcher{
    // margin-left: 70px;
    .app-card-row{
        margin-top: 15px;
        .app-card{
            display: block;
            padding: 20px;
            background: #5666F9;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            color: #ffffff;
            margin-bottom: 30px;
            min-height: 225px;
            position: relative;
            &:hover{
                color:#ffffff;
            }
            .card-details{
                margin: 15px 0 20px;
                font-size: 15px;
            }
            ul{
                &.top-blk{
                    .card-heading{
                        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.20);
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 24px;
                        margin-left: 20px;
                    }
                }
                &.bottom-blk{
                    position: absolute;
                    bottom: 15px;
                    li{
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 22px;
                        &:first-child{
                            margin-right: 20px;
                        }
                        img{
                            position: relative;
                            bottom: 2px;
                        }
                        .restrict-access{
                            @include elementStyles($size: 14px, $color: #fff03b, $fontFamily: $ibmplexRegular);
                        }
                    }
                }
            }
        }
    }
}