@import '../../../../assets/styles/setting';
.action-dock-search{
  background: #fff;
  margin-bottom: 10px;
  border: 1px solid #d3d3d3;
  width: 300px;
  input{
    width: 86%;
  }
  button{
    float: right;
    margin-top: 5px;
    margin-right: 10px;
    img{
      width: 18px;
    }
  }
}
.export-unorg-rec{
  .download-block{
     .dropdown-menu{
      right: -25px;
      left: auto !important;
    }
  }
}
.action-dock-block{
  .download-action-dock{
    position: absolute!important;
    top: 0px!important;
    right: 10px!important;
    .download-block{
      button{
        background: transparent;
        border: none;
        font-family:$ibmplexRegular;
        font-size:12px;
        color:$color_cff;
        &:focus, &:hover, &:active, &.active{
          box-shadow: 0 0 0 !important;
          background: transparent!important;
          color: #2f8cff!important;
        }
        span{
          margin-right:2px;
          img{
            margin-right: 2px;
            position: relative;
            top: -1px;
          }
        }
        &.dropdown-toggle::after{
          position: relative;
          top: 1px;
        }
      }
      .dropdown-menu{
        background:$color_fff;;
        border:1px solid #e9e9e9;
        box-shadow:0 2px 4px 0 rgba(0,0,0,0.20);
        border-radius:3px;
        padding:10px;
        .dropdown-item{
          cursor: pointer;
          padding: 0 0 10px;
          border-bottom: 1px solid #ededed;
          margin-bottom: 10px;
          &:last-child{
            border-bottom: none;
            margin-bottom:0;
            padding-bottom:0;
          }
          img{
            width:12px;
            margin-right:10px;
          }
        }
      }
    }
    .list-inline-item{
      padding: 0px;
      span{
        font-family: $ibmplexRegular!important;
        color: #2F8CFE;
      }
    }
  }
  .no-records-found{
    margin: 0 0;
  }
  margin-left: 270px!important;
  .tab-left{
    border-bottom:1px solid #ddd;
    margin-bottom:25px !important;
    li{
      font-family:$ibmplexRegular;
      font-size:12px;
      color:#8c9ba5 !important;
      text-align:center !important;
    }

  }

  .search-block{
    margin-bottom:18px;
    img{
      width: 14px;
      position: absolute;
      top: 8px;
      left: 245px;
    }
    input[type="text"]{
      opacity:0.74;
      background:#ffffff;
      border:1px solid #dcdcdc;
      width:255px;
      height:30px;
      padding:4px;
    }
  }
  .status-block{
    a{
      font-family:$ibmplexRegular !important;
      font-size:10px;
      margin:0 15px;

      &.reject{
        color:#ff5e5e;
      }
      &.primary-btn{
        padding:7px 30px;
        color: $color_fff;
      }
    }
  }
  .asset-list{
    .record-preview{
      a{
        span{
          font-size: 14px !important;
          word-break: break-all;
          display: block;
        }
      }
      .thet{
        word-break:break-all;
      }
    }
    .restoreRejected{
      a{
        position: absolute;
        right:20px;
        img{
          width: 16px;
        }
      }
    }
    h6.theta{
      font-size: 12px !important;
    }
    h6.thet{
      font-size: 10px !important;
    }
    .para-ui{
      font-family:$ibmplexRegular !important;
      font-size:12px;
      color:#000000;
    }
    .user-logo{
      border-radius: 50%;
      width: 35px;
      height: 35px;
    }
    .dropdown{
      display: inline-block;
      .warning-img{
        margin-right:3px;
        margin-left: -2px;
        width: 10px;
      }
      button{
        font-family:$ibmplexRegular;
        font-size:12px;
        color:#000000;
        border:none;
        &.dropdown-toggle{
          background: #dcdcdc;

        }
        &.publish{
          color:#7ed321;
        }
        &.reject{
          color:#ff5e5e;
        }

        &:active, &:focus{
          box-shadow: 0 0 0;
        }
    }
    .dropdown-menu{
      padding:0 !important;
      button{
        padding: 12px 10px 6px;
        img{
          float:right;
        }

      }
    }
    }

  }
}
